import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Col from 'react-bootstrap/Col';
import Headline from "../components/Headline"
import Section from "../components/Section"
import VimeoEmbed from "../components/VimeoEmbed"
import VimeoChat from "../components/VimeoChat"
import { dateTimeToStr } from "../services/utility"

export default class Event extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isNearOrAfter: false,
      isEventLive: false
    }
  }

  componentDidMount() {
    this.checkEventLive(this.props.data.contentfulMinistryEvent);
    this.timer = setInterval(() => {
      this.checkEventLive(this.props.data.contentfulMinistryEvent);  
    }, 5000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  checkEventLive(ministryEvent) {
    let nowStr = dateTimeToStr(new Date());
    let nearAfter = (typeof ministryEvent.startDate !== 'undefined'
        && dateTimeToStr(ministryEvent.startDate, 0-(15*60*1000)) <= nowStr);
    let isLive = (typeof ministryEvent.startDate !== 'undefined' && typeof ministryEvent.endDate !== 'undefined'
        && dateTimeToStr(ministryEvent.startDate) <= nowStr 
        && dateTimeToStr(ministryEvent.endDate) > nowStr);
    if(nearAfter !== this.state.isNearOrAfter || isLive !== this.state.isEventLive ) {
      this.setState({
        isNearOrAfter: nearAfter,
        isEventLive: isLive
      })
    }
  }
  
  render() {
    let ministryEvent = this.props.data.contentfulMinistryEvent;
    let showVideo = ministryEvent.vimeoId && this.state.isNearOrAfter;
    let showChat = ministryEvent.enableChat && ministryEvent.vimeoId && this.state.isEventLive;
    return ( 
      <Layout>
        <Seo title={`${ministryEvent.name}`} />
        <Section className="white" useCol={false}>
          <Col xs={12} md={8}>
            <Headline>{ministryEvent.name}</Headline>
          </Col>
          <Col xs={12} md={8}>
            { showVideo && <VimeoEmbed vimeoId={ministryEvent.vimeoId} useCinema={ministryEvent.cinematicAspectRatio} /> }
            { !showVideo && ministryEvent.image && <GatsbyImage image={getImage(ministryEvent.image)} alt={ministryEvent.name} /> }
            { ministryEvent.description && <div className="py-3" dangerouslySetInnerHTML={{
                __html: ministryEvent.description.childMarkdownRemark.html,
              }}
            />}
          </Col>
          {showChat && <Col xs={12} md={8} lg={4}>
              <VimeoChat vimeoId={ministryEvent.vimeoId}></VimeoChat>
          </Col>}
          <Col xs={12} className="py-5">
            &nbsp;
          </Col>
        </Section>
      </Layout>
    );
  }
}
export const eventFragment = graphql`
  fragment MinistryEvent on ContentfulMinistryEvent {
    name
    slug
    eventType
    startDate
    endDate
    eventPage
    description {
      childMarkdownRemark {
        html
      }
    }
    vimeoId
    cinematicAspectRatio
    playInline
    enableChat
    vimeoThumbnail {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          width: 600
        )
      }
    }
    image {
      gatsbyImageData(
        layout: CONSTRAINED
        width: 600
        )
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`
export const eventSessionFragment = graphql`
  fragment MinistryEventSessionOnly on ContentfulMinistryEventSession {
    name
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    vimeoId
    cinematicAspectRatio
    startDate
    startChatWindow
    closeChatWindow
    vimeoThumbnail {
      childImageSharp {
        gatsbyImageData(
          width: 370
          height: 200
          )
      }
    }
    sys {
      contentType {
        sys {
          id
        }
      }
    }
  }
`
/*
export const eventSessionUnionFragment = graphql`
  fragment MinistryEventSession on ContentfulMinistryEventSessionContentfulVideoResourceUnion {
      ...MinistryEventSessionOnly
      ...VideoResource
    }
`
*/
export const eventSessionUnionFragment = graphql`
  fragment MinistryEventSession on ContentfulMinistryEventSession {
      ...MinistryEventSessionOnly
    }
`

export const pageQuery = graphql`
query EventBySlug($slug: String!) {
    contentfulMinistryEvent(slug: { eq: $slug }) {
      ...MinistryEvent
    }  
  }
`